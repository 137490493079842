import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Trabaja con nosotros" />
      <section class="jumbotron bg-transparent pb-6" id="header">
    <div class="container">
        <div class="row align-items-center text-center">
            <h1 class="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">¿Quieres unirte al equipo?</h1>
            <p class="lead mb-4 col-md-9 offset-md-2 col-sm-12">Siempre tenemos la puerta del estudio abierta para gente inquieta, con ganas de aprender y que le apasione el desarrollo web. <span>
                    <strong>Si sabes de Drupal, mejor que mejor.</strong>
                </span>
            </p>
        </div>
    </div>
</section>
<section class=" pt-5 bg-light text-left pb-5" id="join-us">
    <div class="container">
        <div class="row text-left">
            <div class="col col-md-9 offset-md-2 col-sm-12">
                <h2>
                    <span>
                        <strong class="text-left">Envíanos un correo a</strong>
                    </span>
                    <br />careers@asdloop.com
                </h2>
                <p class="lead pt-4 pb-4">Cuéntanos qué estás buscando, qué sabes hacer, y un poco sobre tus metas, inquietudes, y todo lo que pueda servirnos para conocerte un poco mejor para ver si puedes tener un hueco en el equipo.</p>
                <h2>Te prometemos que contestaremos ;)</h2>
            </div>
        </div>
    </div>
</section>
    </Layout>
  )
}

export default Contact
